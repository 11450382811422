import axios from 'axios'

// axios.defaults.withCredentials = true
const serve = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: { 'content-type': 'application/json' },
    timeout: 50000
})

// 添加请求拦截器
serve.interceptors.request.use(
    config => {
        // 在发送请求之前做处理
        let token = window.sessionStorage.getItem('token')
            // let entTProjectUuid = window.sessionStorage.getItem('entTProjectUuid')
        if (token) {
            config.headers.authorization = token
        }
        // if (entTProjectUuid) {
        //   config.headers.projectUuid = entTProjectUuid
        // }
        return config
    },
    error => {
        // 对请求错误做处理
        return Promise.reject(error)
    }
)

// 添加响应拦截器
serve.interceptors.response.use(
    response => {
        // 对响应数据做处理
        return response.data
    },
    error => {
        // 对响应错误做处理
        return Promise.reject(error)
    }
)

export default serve